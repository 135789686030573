.headerCover {
  background-image: url('../../../assets/header.jpeg');
  background-blend-mode: darken;
  background-color: rgba(60, 58, 58, 0.6);
  background-position: center;
  height: 30em;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.background-image {
  background-image: url('../../../assets/redbackground.png');
  min-height: 100%;
  height: 120vh;
  min-width: 100%;
  background-blend-mode: darken;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-position: 50%;
  text-align: center;
  background-size: cover;
  width: 100%;
  padding: 0 !important;
}
