/* .splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #14729b;
}

.splash-animation-container {
  height: 10vh;
}
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}
.circle-img {
  border-radius: 50%;
  width: auto;
  height: 140px;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: -70px;
  left: 20px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
} */

.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e4eef3;
}
.logo {
  width: 20vh;
  height: 20vh;
  object-fit: contain;
  object-position: 50% 50%;
  border-radius: 100%;

  animation-name: bounce;
  animation-duration: 2.2s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.47, 0.14, 0.45, 0.94);
  animation-iteration-count: infinite;
}

.shadow {
  width: 20vh;
  height: 1.5vh;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(53, 47, 47));

  animation-name: grow;
  animation-duration: 1.2s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes grow {
  from {
    transform: scale(0.2, 0.5);
  }

  to {
    transform: scale(1, 0.1);
  }
}
