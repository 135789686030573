/* @media (max-width: 993px) {
  .nav-dropDown {
    display: none;
  }
  .searchBtn {
    background-color: transparent;
    display: none;
  }
} */
.modal {
  position: relative !important;
  height: auto !important;
}
input[type="number"] {
  width: 80px;
}
.modal-mobile {
  position: relative !important;
  height: auto !important;
  text-align: center !important;
  padding: 17px 21px !important;
  padding-bottom: 40px !important;
}
.waiting-form {
  margin-left: 10px;
  padding: 17px 21px;
  text-align: none;
}
.form-btn {
  position: relative;
  left: 86%;
}
.header-title {
  color: rgb(255, 255, 255);
  font-size: clamp(25px, 4.5vw, 60px);
  font-family: "ABeeZee", sans-serif;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.reservation {
  background-position: center;
  text-align: center;
  justify-content: center;
  margin-top: -20px;
}
.backgroundImg {
  width: 90rem;
  height: 35rem;
}
.findTable {
  float: none;
  padding: 0px;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 2px 8px rgb(153 153 153 / 40%);
}
.window {
  float: none;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 2px 8px rgb(153 153 153 / 40%);
}
.window-hours {
  float: none;
  padding: 10px;
  width: 195px;
  height: 290px;
  box-shadow: 0 2px 8px rgb(153 153 153 / 40%);
}
.address {
  float: none;
  padding: 10px;
  width: 240px;
  height: auto;
  box-shadow: 0 2px 8px rgb(153 153 153 / 40%);
}
.cover {
  left: 0px;
  top: 0px;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  /* height: 450px; */
  min-height: 350px;
  background-color: rgb(247, 247, 247);
}
.cover-mobile {
  left: 0px;
  top: 0px;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  /* height: 450px; */
  min-height: 180px;
  background-color: rgb(247, 247, 247);
}
.cover-picture {
  width: 100%;
  object-fit: cover;
}
.cover-picture-position-mobile {
  width: 100%;
  min-height: 180px;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.cover-picture-position {
  width: 100%;
  min-height: 350px;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}

.circle-img {
  border-radius: 50%;
  width: auto;
  height: 140px;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: -70px;
  left: 20px;
}
.circle-img-mobile {
  border-radius: 50%;
  width: auto;
  height: 96px;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: -50px;
  left: 20px;
}

.waiting {
  display: flex;
  justify-content: flex-end;
  margin-top: -120px;
  padding-bottom: 60px;
  padding-top: 0px;
  padding-right: 5%;
}
.waiting-mobile {
  display: flex;
  justify-content: flex-end;
  margin-top: -85px;
  padding-bottom: 60px;
  padding-top: 0px;
  padding-right: 5%;
}
