.carouselItem {
  width: '200px';
  height: 270px;
}
.carouselItems {
  height: 250px;
  width: 200px !important;
  box-shadow: 0 2px 8px rgb(153 153 153 / 40%);
}
.carouselItems:hover {
  transform: scale(1.1);
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2.5s;

  /* When the animation is finished, start again */
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.modal {
  position: relative !important;
  margin: 0;
  height: 100% !important;
}
.form-btn {
  position: relative;
  left: auto !important;
}

.modal {
  position: relative !important;
  height: auto !important;
  text-align: center !important;
  padding: 17px 21px !important;
  padding-bottom: 40px !important;
}
.carousel-container {
  justify-content: flex-start;
  margin: 24px;
  border-top: solid;
  border-top-width: 1px;
  padding: 20px;
}
.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 100%;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 25vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}

.ui.search > .results {
  left: inherit !important;
}
.custom-map {
  width: 100% !important;
  height: 25em !important;
}
